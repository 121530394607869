/*
JALISZOOM 0.2
by Pierre Bonnin - @PierreBonninPRO - 2015
*/
(function(factory) {
  'use strict';
  if (typeof define === 'function' && define.amd) {
    // AMD. Register as an anonymous module.
    return define(['jquery'], factory);
  } else if (typeof module === 'object' && module.exports) {
    // Node/CommonJS
    return module.exports = function(root, jQuery) {
      if (jQuery === void 0) {
        // require('jQuery') returns a factory that requires window to
        // build a jQuery instance, we normalize how we use modules
        // that require this pattern but the window provided is a noop
        // if it's defined (how jquery works)
        if (typeof window !== 'undefined') {
          jQuery = require('jquery');
        } else {
          jQuery = require('jquery')(root);
        }
      }
      return factory(jQuery);
    };
  } else {
    // Browser globals
    return factory(jQuery);
  }
})(function($) {
  'use strict';
  var jaliszoom;
  jaliszoom = function(options) {
    var elems;
    elems = this.each(function() {
      var container, defaults, frontCover, img, init, onMouseEnter, onMouseLeave, onMouseMove, prm, setMouseEnter, setMouseLeave, setMouseMove, unsetMouseEnter, unsetMouseLeave, unsetMouseMove, update;
      defaults = {
        imgSelector: 'img',
        zoomCssClass: 'fiche-image-cover',
        autoupdate: true
      };
      prm = $.extend(defaults, options);
      container = $(this);
      img = $(this).find(prm.imgSelector);
      frontCover = void 0;
      init = function() {
        frontCover = '<span class=\'' + prm.zoomCssClass + '\' style=\'background-image:url(' + img.attr('src') + ');\'></span>';
        container.append(frontCover);
        return setMouseEnter();
      };
      onMouseMove = function(e) {
        var mousePos, offset, ratios, sizes;
        offset = container.offset();
        sizes = {
          width: container.width(),
          height: container.height()
        };
        mousePos = {
          top: e.pageY - offset.top,
          left: e.pageX - offset.left
        };
        ratios = {
          top: mousePos.top / sizes.height * 100,
          left: mousePos.left / sizes.width * 100
        };
        return container.find('.' + prm.zoomCssClass).css('background-position', ratios.left + '% ' + ratios.top + '%');
      };
      onMouseEnter = function(_e) {
        unsetMouseEnter();
        if (prm.autoupdate) {
          update();
        }
        setMouseLeave();
        return setMouseMove();
      };
      onMouseLeave = function(_e) {
        unsetMouseLeave();
        unsetMouseMove();
        return setMouseEnter();
      };
      setMouseEnter = function() {
        return container.on('mouseenter', onMouseEnter);
      };
      unsetMouseEnter = function() {
        return container.off('mouseenter', onMouseEnter);
      };
      setMouseLeave = function() {
        return container.on('mouseleave', onMouseLeave);
      };
      unsetMouseLeave = function() {
        return container.off('mouseleave', onMouseLeave);
      };
      setMouseMove = function() {
        return container.on('mousemove', onMouseMove);
      };
      unsetMouseMove = function() {
        return container.off('mousemove', onMouseMove);
      };
      update = function() {
        var cover, image;
        cover = container.find('.' + prm.zoomCssClass);
        image = container.find(prm.imgSelector);
        if (!cover.css('background-image').match(image.attr('src'))) {
          return cover.css('background-image', 'url(' + image.attr('src'));
        }
      };
      init();
      this.update = update;
      this.stop = function() {
        unsetMouseEnter();
        unsetMouseLeave();
        unsetMouseMove();
        return $('.' + prm.zoomCssClass).remove();
      };
      this.reset = function() {
        this.stop();
        return init();
      };
      return this;
    });
    this.update = function() {
      var i, results;
      i = 0;
      results = [];
      while (i < elems.length) {
        $(elems[i])[0].update();
        results.push(i++);
      }
      return results;
    };
    return this;
  };
  return $.fn.jaliszoom = jaliszoom;
});
