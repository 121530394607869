/*
JALISINVIEW 0.2
by Pierre Bonnin - @PierreBonninPRO - 2015
*/
(function(factory) {
  'use strict';
  if (typeof define === 'function' && define.amd) {
    // AMD. Register as an anonymous module.
    return define(['jquery'], factory);
  } else if (typeof module === 'object' && module.exports) {
    // Node/CommonJS
    return module.exports = function(root, jQuery) {
      if (jQuery === void 0) {
        // require('jQuery') returns a factory that requires window to
        // build a jQuery instance, we normalize how we use modules
        // that require this pattern but the window provided is a noop
        // if it's defined (how jquery works)
        if (typeof window !== 'undefined') {
          jQuery = require('jquery');
        } else {
          jQuery = require('jquery')(root);
        }
      }
      return factory(jQuery);
    };
  } else {
    // Browser globals
    return factory(jQuery);
  }
})(function($) {
  'use strict';
  var jalisinview;
  jalisinview = function(options) {
    var defaults, get_available_height, init, prm, refresh, resize;
    init = function() {
      refresh();
      $(window).on('scroll', function(_e) {
        return refresh();
      });
      if (prm.resize) {
        return $(window).on('resize', function() {
          return resize();
        });
      }
    };
    get_available_height = function() {
      return $(window).height();
    };
    refresh = () => {
      var available_height, st;
      st = $(window).scrollTop();
      available_height = get_available_height();
      return this.elems.each(function() {
        var bottom, item, on_and_off, top;
        item = $(this);
        top = item.offset().top;
        bottom = top + item.outerHeight();
        on_and_off = prm.on_and_off;
        if (item.attr('data-jalisinview') !== void 0) {
          on_and_off = item.attr('data-jalisinview') === 'both';
        }
        if (top <= available_height + st) {
          item.removeClass(prm.class_off);
          item.addClass(prm.class_on);
          if (bottom < st && on_and_off) {
            return item.removeClass(prm.class_on);
          }
        } else if (on_and_off) {
          item.removeClass(prm.class_on);
          return item.addClass(prm.class_off);
        }
      });
    };
    resize = function() {
      return refresh();
    };
    defaults = {
      class_on: 'view-on',
      class_off: 'view-off',
      on_and_off: false,
      html_attribute: 'data-jalisinview',
      resize: true
    };
    prm = $.extend(defaults, options);
    this.elems = this;
    this.prm = prm;
    init();
    this.reset = () => {
      $(window).off('scroll');
      $(window).off('resize');
      return this.elems.removeClass(prm.class_off).removeClass(prm.class_on);
    };
    this.restart = function() {
      this.reset();
      return init();
    };
    this.resize = resize;
    this.update = function() {
      return refresh();
    };
    return this;
  };
  return $.fn.jalisinview = jalisinview;
});
