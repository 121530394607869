/*
JALISSTICKY 0.2
by Pierre Bonnin - @PierreBonninPRO - 2015
*/
// coffeelint: disable=max_line_length
(function(factory) {
  'use strict';
  if (typeof define === 'function' && define.amd) {
    // AMD. Register as an anonymous module.
    return define(['jquery'], factory);
  } else if (typeof module === 'object' && module.exports) {
    // Node/CommonJS
    return module.exports = function(root, jQuery) {
      if (jQuery === void 0) {
        // require('jQuery') returns a factory that requires window to
        // build a jQuery instance, we normalize how we use modules
        // that require this pattern but the window provided is a noop
        // if it's defined (how jquery works)
        if (typeof window !== 'undefined') {
          jQuery = require('jquery');
        } else {
          jQuery = require('jquery')(root);
        }
      }
      return factory(jQuery);
    };
  } else {
    // Browser globals
    return factory(jQuery);
  }
})(function($) {
  'use strict';
  var jalisSticky;
  jalisSticky = function(options) {
    return this.each(function() {
      var debord, defaults, getDebord, init, initialTop, initialTopValue, obj, objHeight, offsetTop, prm, scrolltop, update, updateValues, winHeight;
      defaults = {
        cssClass: 'sticked',
        topModulator: function() {
          return 0;
        },
        onStick: function() {
          return void 0;
        },
        onUnStick: function() {
          return void 0;
        },
        eventType: 'scroll',
        debug: false
      };
      prm = $.extend(defaults, options);
      obj = $(this);
      initialTop = obj.css('top');
      initialTopValue = initialTop === 'auto' || initialTop === 'inherit' || initialTop === void 0 ? 0 : parseInt(initialTop);
      winHeight = void 0;
      scrolltop = void 0;
      offsetTop = void 0;
      objHeight = void 0;
      debord = void 0;
      init = function() {
        scrolltop = $(window).scrollTop();
        offsetTop = obj.offset().top;
        updateValues();
        offsetTop -= debord.size;
        $(window).on(prm.eventType, update);
        // if prm.debug
        //   console.log 'jalis sticky init'
        return update();
      };
      updateValues = function() {
        winHeight = $(window).height();
        scrolltop = $(window).scrollTop();
        objHeight = obj.outerHeight(true);
        return debord = getDebord();
      };
      getDebord = function() {
        var ret;
        ret = {
          is: false,
          size: 0
        };
        if (objHeight + offsetTop > winHeight) {
          ret.is = true;
          ret.size = winHeight - (objHeight + offsetTop);
        }
        return ret;
      };
      update = function() {
        var local_offsetTop, top;
        updateValues();
        local_offsetTop = offsetTop - prm.topModulator();
        if (scrolltop > local_offsetTop && objHeight < obj.parent().innerHeight()) {
          top = scrolltop - local_offsetTop + initialTopValue;
          return obj.css('top', top);
        } else {
          // if prm.debug
          //   console.log 'Element :'
          //   console.log obj
          //   console.log '***'
          //   console.log 'offsetTop : ' + offsetTop
          //   console.log 'scrolltop : ' + scrolltop
          //   console.log 'localoffsetTop : ' + local_offsetTop
          //   console.log 'initialTop : ' + initialTop
          //   console.log 'initialTopValue : ' + initialTopValue
          //   console.log 'top : ' + top
          //   console.log '----------------'
          return obj.css('top', initialTop);
        }
      };
      // if prm.debug
      //   console.log 'Unsticked Element :'
      //   console.log obj
      //   console.log '----------------'
      init();
      this.kill = function() {
        return $(window).off(prm.eventType, update);
      };
      return this;
    });
  };
  return $.fn.jalisSticky = jalisSticky;
});

// coffeelint: enable=max_line_length
