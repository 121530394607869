(function(factory) {
  'use strict';
  if (typeof define === 'function' && define.amd) {
    // AMD. Register as an anonymous module.
    return define(['jquery'], factory);
  } else if (typeof module === 'object' && module.exports) {
    // Node/CommonJS
    return module.exports = function(root, jQuery) {
      if (jQuery === void 0) {
        // require('jQuery') returns a factory that requires window to
        // build a jQuery instance, we normalize how we use modules
        // that require this pattern but the window provided is a noop
        // if it's defined (how jquery works)
        if (typeof window !== 'undefined') {
          jQuery = require('jquery');
        } else {
          jQuery = require('jquery')(root);
        }
      }
      return factory(jQuery);
    };
  } else {
    // Browser globals
    return factory(jQuery);
  }
})(function($) {
  'use strict';
  var addResize, concerto_config, goClic, is_iOs;
  is_iOs = function() {
    var ret, uA;
    ret = false;
    uA = navigator.userAgent;
    if (uA.match('Version/7.0')) {
      ret = true;
    }
    return ret;
  };
  addResize = function() {
    var hauteur_tuile1, hauteur_tuile3, hauteur_tuile4;
    hauteur_tuile1 = $('.width20').width();
    // hauteur_tuile2 = $('.width33').width()
    hauteur_tuile3 = $('.width25').width();
    hauteur_tuile4 = $('#damier').width();
    $('.width20').css({
      'height': hauteur_tuile1
    });
    $('.width40').css({
      'height': hauteur_tuile1
    });
    $('.width25').css({
      'height': hauteur_tuile3
    });
    $('.width33').css({
      'height': hauteur_tuile4 * 0.33
    });
    return $('.width67').css({
      'height': hauteur_tuile4 * 0.67
    });
  };
  goClic = function(elem) {
    var scrollzone;
    // element ou est applique l'ecouteur de scroll
    scrollzone = arguments[1] !== void 0 ? arguments[1] : 'html, body';
    return $(elem).each(function() {
      var desc, obj, origin;
      obj = $(this);
      desc = $(obj.attr('data-go'));
      origin = obj.attr('data-go-origin');
      obj.off('click');
      return obj.on('click', function(e) {
        var h, pos;
        desc = $(obj.attr('data-go'));
        e.preventDefault();
        h = void 0;
        if (obj.attr('data-go') === 'top') {
          h = 0;
        } else {
          pos = desc.offset().top - ($(scrollzone).offset().top);
          if (scrollzone !== 'html, body') {
            pos += $(scrollzone).scrollTop();
          }
          if (desc.outerHeight() > $(window).height() * 0.8) {
            h = pos - ($(window).height() / 2);
          } else {
            h = pos - (($(window).height() - desc.innerHeight()) / 2);
          }
          if (origin !== void 0) {
            if (origin === 'top') {
              h = pos;
            } else if (origin === 'bottom') {
              h = pos - (desc.offset().top - desc.innerHeight());
            }
          }
        }
        return $(scrollzone).animate({
          scrollTop: h
        }, {
          queue: false,
          duration: 750
        });
      });
    });
  };
  concerto_config = {
    handleTouchNav: false,
    watchScroll: true,
    menuMobile: true,
    scrollPoint: function() {
      return $('nav.nav').offset().top;
    },
    load: function() {},
    ready: function() {
      var diaporamaAccueilSize;
      diaporamaAccueilSize = function() {
        return $('.diaporama--fond').css('height', $(window).height());
      };
      if (is_iOs()) {
        // rajoute au body la classe badios si ios7
        $('html').addClass('badios');
      }
      /* Pied de page */
      if ($.jgo.elementExiste('footer')) {
        $('#footer-bt').click(function() {
          return $('footer').toggleClass('ouvre');
        });
        $('#total_site').click(function() {
          return $('footer').removeClass('ouvre');
        });
      }
      // diaporamas et slideshows
      $.site.diaporama = new $.jgo.slideshow({
        conteneur: '.diaporama--fond',
        afficheAleatoire: true
      });
      addResize();
      $.jgo.handledResize.push(addResize);
      $.site.bodyFond();
      // Our team page
      $('.wall').jaliswall({
        item: '.article'
      });
      // accordeon
      $.site.accordeon = new ($.jgo.accordeon)();
      // fourrage en background-image d'un img
      $.site.imgback = new $.jgo.imgback({
        elements: ['.img--back', '.slide', '.ico']
      });
      // surveillance du viewport
      $.site.vsy = $('.vsy').jalisinview();
      // clic & scroll
      goClic('.go');
      // seuil de responsive pour les annonces
      $('.ann').jalisInnerResponsive({
        step: 540,
        cssClass: 'ann-gallerie'
      });
      $('*[class*="grille-annonce--"] .ann').jalisInnerResponsive({
        step: 265
      });
      $('.grille-annonce .ann').jalisInnerResponsive({
        step: 340
      });
      $('.ann-gallerie--survol').jalisInnerResponsive({
        step: 320,
        cssClass: 'small--survol'
      });
      // zoom quand survol
      $.site.zoom = $('.zoom').jaliszoom({
        zoomCssClass: 'fiche-img-cover'
      });
      // sticky
      $.site.sticky = $('.sticky').jalisSticky({
        topModulator: function() {
          return $('body.scrolled nav').outerHeight(true);
        }
      });
      // liens miniatures / img
      $('.grille-images .miniatures *[class*="img"]').mouseover(function(e) {
        $('.fiche-img > img, .fiche-img > .zoom > img').attr('src', $(e.target).attr('href'));
        try {
          return $.site.zoom.update();
        } catch (error) {
          e = error;
          return false;
        }
      });
      if (!Modernizr.flexbox) {
        $.site.paddingMenu = new ($.jgo.paddingMenu)();
      }
      if (!Modernizr.cssvhunit || is_iOs()) {
        diaporamaAccueilSize();
        return $.jgo.handledResize.push(diaporamaAccueilSize);
      }
    }
  };
  $.jgo.prm = concerto_config;
  return $.site.bodyFond = function() {
    if ($.jgo.elementExiste('.reference')) {
      $('body').addClass('reference');
    }
    if ($.jgo.elementExiste('.theVente')) {
      return $('body').addClass('vendre');
    }
  };
});
