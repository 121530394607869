// Load Sentry
var AmMap, map_logo;

import * as Sentry from "@sentry/browser";

window.Sentry = Sentry;

import $ from 'jquery';

require('jquery-ui/ui/widgets/dialog');

import Rails from '@rails/ujs';

Rails.start();

import "@hotwired/turbo-rails";

import Modernizr from 'modernizr';

window.Modernizr = Modernizr;

import {
  // Load jQuery Fancybox
  Fancybox
} from '@fancyapps/ui';

window.Fancybox = Fancybox;

import * as am4core from '@amcharts/amcharts4/core';

import * as am4maps from '@amcharts/amcharts4/maps';

import am4geodata_franceHigh from '@amcharts/amcharts4-geodata/franceHigh';

import am4geodata_spainHigh from '@amcharts/amcharts4-geodata/spainHigh';

import am4geodata_belgiumHigh from '@amcharts/amcharts4-geodata/belgiumHigh';

import am4geodata_portugalHigh from '@amcharts/amcharts4-geodata/portugalHigh';

AmMap = {};

AmMap['am4core'] = am4core;

AmMap['am4maps'] = am4maps;

AmMap['maps'] = {};

AmMap['maps']['fr'] = am4geodata_franceHigh;

AmMap['maps']['es'] = am4geodata_spainHigh;

AmMap['maps']['be'] = am4geodata_belgiumHigh;

AmMap['maps']['pt'] = am4geodata_portugalHigh;

window.AmMap = AmMap;

if (window.webkit == null) {
  // Workaround for error in webkit browsers
  // undefined is not an object (evaluating 'window.webkit.messageHandlers')
  // See: https://github.com/getsentry/sentry-javascript/issues/3040#issuecomment-913549441
  // See: https://stackoverflow.com/a/56900034
  window.webkit = {};
}

// Load CookieChoices
require('vendor/javascripts/cookiechoices/cookiechoices.coffee');

// Load Jalis plugins
require('vendor/javascripts/jalis/plugins/jalis_inner_responsive.coffee');

require('vendor/javascripts/jalis/plugins/jalis_inview.coffee');

require('vendor/javascripts/jalis/plugins/jalis_sticky.coffee');

require('vendor/javascripts/jalis/plugins/jalis_wall.coffee');

require('vendor/javascripts/jalis/plugins/jalis_zoom.coffee');

// Load Jalis JS
require('vendor/javascripts/jalis/pack.coffee');

require('vendor/javascripts/jalis/site.coffee');

import JalisLoader from 'vendor/javascripts/jalis/load.coffee';

window.JalisLoader = JalisLoader;

// Load images
require('vendor/images/concerto/slider/slide-beach.jpg');

require('vendor/images/concerto/slider/slide-fish.jpg');

require('vendor/images/concerto/slider/slide-sun.jpg');

require('vendor/images/concerto/logos/french-site.png');

require('vendor/images/concerto/logos/french-site-top.png');

require('vendor/images/concerto/logos/french-site-bottom.png');

require('vendor/images/concerto/no_img/website-small.jpg');

map_logo = require('vendor/images/concerto/logos/map-logo.svg');

window.JalisLoader.map_logo = map_logo;

require('vendor/images/jalis/fb.png');

require('vendor/images/jalis/france.png');

require('vendor/images/jalis/concerto-logo.png');

import 'styles/concerto_france/jalis.scss';
