(function(factory) {
  'use strict';
  if (typeof define === 'function' && define.amd) {
    // AMD. Register as an anonymous module.
    return factory(window);
  } else if (typeof module === 'object') {
    // Node/CommonJS
    return module.exports = function(root) {
      if (!root) {
        // CommonJS environments without a window global must pass a
        // root. This will give an error otherwise
        root = window;
      }
      return factory(root);
    };
  } else {
    // Browser globals
    return factory(window);
  }
})(function(window) {
  'use strict';
  var cookieChoices, document, supportsTextContent;
  if (!!window.cookieChoices) {
    return window.cookieChoices;
  }
  document = window.document;
  // console.log(document);

  // IE8 does not support textContent, so we should fallback to innerText.
  // var supportsTextContent = 'textContent' in document.body;
  supportsTextContent = true;
  cookieChoices = (function() {
    var _createConsentText, _createDialogElement, _createDismissLink, _createHeaderElement, _createInformationLink, _dismissLinkClick, _removeCookieConsent, _saveUserPreference, _setElementText, _shouldDisplayConsent, _showCookieConsent, cookieConsentId, cookieName, dismissLinkId, exports, showCookieConsentBar, showCookieConsentDialog;
    cookieName = 'displayCookieConsent';
    cookieConsentId = 'cookieChoiceInfo';
    dismissLinkId = 'cookieChoiceDismiss';
    exports = {};
    _createHeaderElement = function(cookieText, dismissText, linkText, linkHref) {
      var butterBarStyles, cookieConsentElement;
      butterBarStyles = '';
      cookieConsentElement = document.createElement('div');
      cookieConsentElement.id = cookieConsentId;
      cookieConsentElement.style.cssText = butterBarStyles;
      cookieConsentElement.appendChild(_createConsentText(cookieText));
      if (!!linkText && !!linkHref) {
        cookieConsentElement.appendChild(_createInformationLink(linkText, linkHref));
      }
      cookieConsentElement.appendChild(_createDismissLink(dismissText));
      return cookieConsentElement;
    };
    _createDialogElement = function(cookieText, dismissText, linkText, linkHref) {
      var content, contentStyle, cookieConsentElement, dialog, dialogStyle, dismissLink, glassPanel, glassStyle;
      glassStyle = '';
      dialogStyle = '';
      contentStyle = '';
      cookieConsentElement = document.createElement('div');
      cookieConsentElement.id = cookieConsentId;
      glassPanel = document.createElement('div');
      glassPanel.style.cssText = glassStyle;
      content = document.createElement('div');
      content.style.cssText = contentStyle;
      dialog = document.createElement('div');
      dialog.style.cssText = dialogStyle;
      dismissLink = _createDismissLink(dismissText);
      dismissLink.style.display = 'block';
      dismissLink.style.textAlign = 'right';
      dismissLink.style.marginTop = '8px';
      content.appendChild(_createConsentText(cookieText));
      if (!!linkText && !!linkHref) {
        content.appendChild(_createInformationLink(linkText, linkHref));
      }
      content.appendChild(dismissLink);
      dialog.appendChild(content);
      cookieConsentElement.appendChild(glassPanel);
      cookieConsentElement.appendChild(dialog);
      return cookieConsentElement;
    };
    _setElementText = function(element, text) {
      if (supportsTextContent) {
        return element.textContent = text;
      } else {
        return element.innerText = text;
      }
    };
    _createConsentText = function(cookieText) {
      var consentText;
      consentText = document.createElement('span');
      _setElementText(consentText, cookieText);
      return consentText;
    };
    _createDismissLink = function(dismissText) {
      var dismissLink;
      dismissLink = document.createElement('a');
      _setElementText(dismissLink, dismissText);
      dismissLink.id = dismissLinkId;
      dismissLink.href = '#';
      dismissLink.style.marginLeft = '0px';
      return dismissLink;
    };
    _createInformationLink = function(linkText, linkHref) {
      var infoLink;
      infoLink = document.createElement('a');
      _setElementText(infoLink, linkText);
      infoLink.href = linkHref;
      infoLink.target = '_parent';
      infoLink.style.marginLeft = '8px';
      return infoLink;
    };
    _dismissLinkClick = function() {
      _saveUserPreference();
      _removeCookieConsent();
      return false;
    };
    _showCookieConsent = function(cookieText, dismissText, linkText, linkHref, isDialog) {
      var consentElement, fragment;
      if (_shouldDisplayConsent()) {
        _removeCookieConsent();
        consentElement = isDialog ? _createDialogElement(cookieText, dismissText, linkText, linkHref) : _createHeaderElement(cookieText, dismissText, linkText, linkHref);
        fragment = document.createDocumentFragment();
        fragment.appendChild(consentElement);
        document.body.appendChild(fragment.cloneNode(true));
        return document.getElementById(dismissLinkId).onclick = _dismissLinkClick;
      }
    };
    showCookieConsentBar = function(cookieText, dismissText, linkText, linkHref) {
      return _showCookieConsent(cookieText, dismissText, linkText, linkHref, false);
    };
    showCookieConsentDialog = function(cookieText, dismissText, linkText, linkHref) {
      return _showCookieConsent(cookieText, dismissText, linkText, linkHref, true);
    };
    _removeCookieConsent = function() {
      var cookieChoiceElement;
      cookieChoiceElement = document.getElementById(cookieConsentId);
      if (cookieChoiceElement !== null) {
        return cookieChoiceElement.parentNode.removeChild(cookieChoiceElement);
      }
    };
    _saveUserPreference = function() {
      var expiryDate;
      // Set the cookie expiry to one year after today.
      expiryDate = new Date();
      expiryDate.setFullYear(expiryDate.getFullYear() + 1);
      return document.cookie = cookieName + '=y; expires=' + expiryDate.toGMTString();
    };
    _shouldDisplayConsent = function() {
      return !document.cookie.match(new RegExp(cookieName + '=([^;]+)'));
    };
    exports.showCookieConsentBar = showCookieConsentBar;
    exports.showCookieConsentDialog = showCookieConsentDialog;
    return exports;
  })();
  window.cookieChoices = cookieChoices;
  return cookieChoices;
});
