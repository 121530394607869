/*
_______________________________________________________
JALIS JGO v.5.2.1 BATMAN - (c) 2011, 2015 JALIS
requires modernizr+jquery 1.11.0
________________________________________________________
*/
// coffeelint: disable=max_line_length
(function(factory) {
  'use strict';
  if (typeof define === 'function' && define.amd) {
    // AMD. Register as an anonymous module.
    return define(['jquery'], factory);
  } else if (typeof module === 'object' && module.exports) {
    // Node/CommonJS
    return module.exports = function(root, jQuery) {
      if (jQuery === void 0) {
        // require('jQuery') returns a factory that requires window to
        // build a jQuery instance, we normalize how we use modules
        // that require this pattern but the window provided is a noop
        // if it's defined (how jquery works)
        if (typeof window !== 'undefined') {
          jQuery = require('jquery');
        } else {
          jQuery = require('jquery')(root);
        }
      }
      return factory(jQuery);
    };
  } else {
    // Browser globals
    return factory(jQuery);
  }
})(function($) {
  'use strict';
  var Jalis, jgo;
  Jalis = {
    checkAddedCode: function(e, t) {
      var i, n;
      n = arguments[2] ? arguments[2] : void 0;
      for (i in e) {
        e[i][1] === t && e[i][0](n);
      }
    },
    identifier: {
      outilscentreimage: 'jgo_cee',
      accordeon: 'jgo_acc',
      slideshow: 'jgo_slw',
      infobulle: 'jgo_ibe'
    },
    exists: function(e) {
      return typeof e !== 'undefined';
    }
  };
  Jalis.exists($.site) || ($.site = new Object());
  jgo = {
    version: '5.2.1 BATMAN',
    prm: {
      defaultActiveClass: 'active',
      transitionEnd: 'webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend webkitAnimationEnd oanimationend msAnimationEnd animationend',
      handleTouchNav: true,
      debug: false,
      replaceSVGtoPNG: true,
      cssSvgBackground: [],
      handleTextInput: true,
      textInputException: 'input[type=text]:not(.noErase), textarea:not(.noErase)',
      handleIframeWmode: true,
      handleResize: true,
      watchScroll: true,
      scrollPoint: function() {
        return $('nav.nav').offset().top;
      },
      menuMobile: true,
      menuMobileReleaseElems: ['.contenu', '#logo'],
      ready: function() {},
      load: function() {}
    },
    nav: {
      bon: Modernizr.cssanimations && Modernizr.backgroundsize && Modernizr.svg,
      tactile: Modernizr.touchevents || window.PointerEvent !== void 0,
      mobile: new RegExp(/(IEMobile|mobile|Android|iPad|iPhone|iPod|BlackBerry|Windows Phone)/gi).test(navigator.userAgent.toLowerCase())
    },
    handledResize: [],
    globalResize: function() {
      var e, results;
      if ($.jgo.handledResize.length > 0) {
        results = [];
        for (e in $.jgo.handledResize) {
          results.push($.jgo.handledResize[e]());
        }
        return results;
      }
    },
    elementExiste: function(t) {
      return $(t).html() !== void 0;
    },
    clean: function(e) {
      return e.replace(new RegExp(/\.|#| |>|</gi), '');
    },
    jgoLog: function(_e) {},
    // console.log 'JGO ' + $.jgo.version + ' - ' + e
    getCssAnimationStatus: function(e) {
      var i, n, s, t;
      i = void 0;
      n = void 0;
      s = void 0;
      t = void 0;
      n = {};
      t = function(e) {
        return parseFloat(e.replace(new RegExp(/[a-z]/gi), ''));
      };
      i = void 0 !== e.css('transition-duration') ? t(e.css('transition-duration')) : 0;
      s = void 0 !== e.css('animation-duration') ? t(e.css('animation-duration')) : 0;
      n.is = s > 0 || i > 0;
      n.is && (n.type = i > 0 ? 'transition' : 'animation');
      n.duration = 'transition' === n.type ? i : s;
      return n;
    },
    accordeon: function() {
      var accordion, arg, generate_id, l, o, r, s;
      s = new Object();
      arg = void 0;
      arg = arguments[0] ? arguments[0] : !1;
      s.duree = 0 !== arg && Jalis.exists(arg.duree) ? arg.duree : 250;
      s.obj = 0 !== arg && Jalis.exists(arg.objet) ? arg.objet : '.accordeon';
      s.objClick = 0 !== arg && Jalis.exists(arg.objetClic) ? arg.objetClic : '.accordeonTitre';
      s.objHidden = 0 !== arg && Jalis.exists(arg.objetCache) ? arg.objetCache : '.accordeonContenu';
      s.objStillVisible = 0 !== arg && Jalis.exists(arg.objStillVisible) ? arg.objStillVisible : '.accordeonContenuVisible';
      s.objMesure = 0 !== arg && Jalis.exists(arg.objMesure) ? arg.objMesure : 'div';
      s.openAtStart = 0 !== arg && Jalis.exists(arg.openAtStart) ? arg.openAtStart : !1;
      s.closeOthersOnClick = 0 !== arg && Jalis.exists(arg.closeOthersOnClick) ? arg.closeOthersOnClick : !0;
      s.okResize = 0 !== arg && Jalis.exists(arg.okResize) ? arg.okResize : $.jgo.prm.handleResize;
      s.classeActive = 0 !== arg && Jalis.exists(arg.classeActive) ? arg.classeActive : $.jgo.prm.defaultActiveClass;
      s.autoinit = 0 !== arg && Jalis.exists(arg.autoinit) ? arg.autoinit : !0;
      s.elems = [];
      this.prm = s;
      generate_id = function(e) {
        return Jalis.identifier.accordeon + '_' + $.jgo.clean(s.obj) + '_' + e;
      };
      accordion = function(id, index) {
        var A, C, b, c, d, f, g, h, m, o, p, u, v, w, x;
        h = void 0;
        f = void 0;
        m = void 0;
        v = void 0;
        o = function() {
          return Jalis.checkAddedCode(l, 'clickOpen', u());
        };
        c = function() {
          return Jalis.checkAddedCode(l, 'clickClose', u());
        };
        u = function() {
          return {
            id: id,
            index: index,
            conteneur: h,
            contenu: f
          };
        };
        g = function() {
          return f.find(s.objMesure).height();
        };
        d = function() {
          var e;
          e = 0;
          return e += h.find(s.objStillVisible).outerHeight(!0);
        };
        this.init = function() {
          h = $('#' + id);
          f = h.find(s.objHidden);
          m = g();
          v = d();
          if (s.openAtStart) {
            p();
          } else {
            b(!0);
          }
          return h.find(s.objClick).on('click', function() {
            return A();
          });
        };
        this.kill = function() {
          return h.find(s.objClick).off('click');
        };
        this.reset = function() {
          w();
          return f.css('height', '');
        };
        this.restart = function() {
          C();
          return r();
        };
        this.ouvre = function() {
          var anim;
          if (!h.hasClass(s.classeActive)) {
            // if s.closeOthersOnClick
            //   for e of s.elems
            //     s.elems[e].ferme()
            Jalis.checkAddedCode(l, 'clickOpenInit', u());
            f.css('height', m);
            h.addClass(s.classeActive);
            anim = $.jgo.getCssAnimationStatus(f);
            if (anim.is) {
              return f.on($.jgo.prm.transitionEnd, function() {
                f.off($.jgo.prm.transitionEnd);
                return o();
              });
            } else {
              return o();
            }
          }
        };
        this.ferme = function() {
          var e;
          if (h.hasClass(s.classeActive) || 1 === arguments[0]) {
            Jalis.checkAddedCode(l, 'clickCloseInit', u());
            f.css('height', v);
            h.removeClass(s.classeActive);
            e = $.jgo.getCssAnimationStatus(f);
            if (e.is) {
              return f.on($.jgo.prm.transitionEnd, function() {
                f.off($.jgo.prm.transitionEnd);
                return c();
              });
            } else {
              return c();
            }
          }
        };
        this.toggle = function() {
          if (h.hasClass(s.classeActive)) {
            return b();
          } else {
            return p();
          }
        };
        this.resize = function() {
          f.css('height', 'auto');
          m = g();
          v = d();
          if (h.hasClass(s.classeActive)) {
            return f.css('height', m);
          } else {
            return f.css('height', v);
          }
        };
        p = this.ouvre;
        b = this.ferme;
        A = this.toggle;
        x = this.init;
        w = this.kill;
        C = this.reset;
        return x();
      };
      this.init = function() {
        s.okResize && $.jgo.handledResize.push(o);
        return $(s.obj).each(function(index) {
          var id;
          id = generate_id(index);
          $(this).attr('id', id);
          return s.elems.push(new accordion(id, index));
        });
      };
      this.ferme = function() {
        var e, results;
        results = [];
        for (e in s.elems) {
          results.push(s.elems[e].ferme());
        }
        return results;
      };
      this.ouvre = function() {
        var e, results;
        results = [];
        for (e in s.elems) {
          results.push(s.elems[e].ouvre());
        }
        return results;
      };
      this.toggle = function() {
        var e, results;
        results = [];
        for (e in s.elems) {
          results.push(s.elems[e].toggle());
        }
        return results;
      };
      this.kill = function() {
        var e, results;
        results = [];
        for (e in s.elems) {
          results.push(s.elems[e].kill());
        }
        return results;
      };
      this.reset = function() {
        var e, results;
        results = [];
        for (e in s.elems) {
          results.push(s.elems[e].reset());
        }
        return results;
      };
      this.restart = function() {
        var e, results;
        results = [];
        for (e in s.elems) {
          results.push(s.elems[e].restart());
        }
        return results;
      };
      this.resize = function() {
        var e, results;
        results = [];
        for (e in s.elems) {
          results.push(s.elems[e].resize());
        }
        return results;
      };
      this.ajoutFonction = function(e, t) {
        var n;
        if ('clickOpen' === t || 'clickClose' === t || 'clickOpenInit' === t || 'clickCloseInit' === t) {
          n = new Array(e, t);
          return l.push(n);
        }
      };
      r = this.init;
      this.kill;
      this.reset;
      this.restart;
      o = this.resize;
      l = new Array();
      this.ajoutFonction;
      s.autoinit && $.jgo.elementExiste(s.obj) && this.init();
    },
    slideshow: function() {
      var a, arg, c, i, l, o, r, s;
      a = new Object();
      arg = void 0;
      i = function(t, i) {
        var s;
        s = $(i).eq(t);
        if (s.attr('id') === $.jgo.clean(i)) {
          return $.jgo.clean(i);
        } else {
          return $.jgo.clean(i) + '_' + t;
        }
      };
      s = function(s) {
        var A, B, C, E, G, H, I, K, M, N, O, P, R, S, T, _, b, d, f, g, h, j, k, m, p, u, v, w, x, y, z;
        y = [];
        z = [];
        R = [];
        S = [];
        B = [];
        M = [];
        O = [];
        P = [0];
        T = !1;
        I = !1;
        N = {};
        G = {};
        u = function() {
          var n, o, r, t;
          t = $('#' + i(s, a.exempleBouton)).html();
          n = $('#' + i(s, a.repereBoutons));
          r = '';
          for (o in M) {
            o;
            r += t;
          }
          n.html(r);
          return n.find(a.classeBt).each(function(t) {
            $(this).on('click', function() {
              return _(t);
            });
            return a.placeNum && $(this).html(t + 1);
          });
        };
        g = function() {
          N.left.click(function() {
            return T || H();
          });
          return N.right.click(function() {
            return T || K();
          });
        };
        d = function() {
          if (y.children(a.element).length === 0 && y.children('img').length > 0) {
            return y.children('img').each(function() {
              return $(this).wrap('<div class="' + $.jgo.clean(a.element) + '"></div>');
            });
          }
        };
        h = function(e) {
          e.preventDefault();
          I = !0;
          return C();
        };
        f = function(e) {
          e.preventDefault();
          I = !1;
          if (!T && S > 1) {
            return w();
          }
        };
        m = function(e, t) {
          var anim;
          i = function() {
            M[e].removeClass(a.classeNonActive);
            b(e);
            s = $.jgo.getCssAnimationStatus(M[e]);
            if (s.is) {
              M[e].on($.jgo.prm.transitionEnd, (function(t) {
                return t.target === t.currentTarget && M[e].off($.jgo.prm.transitionEnd).attr('class', $.jgo.clean(a.element));
              }));
            } else {
              M[e].attr('class', $.jgo.clean(a.element));
            }
            return t && v(z);
          };
          if (y.find(a.classeNonActive).length > 1) {
            M[e].removeClass(a.classeActive);
            return i();
          } else {
            M[e].removeClass(a.classeActive).addClass(a.classeNonActive);
            anim = $.jgo.getCssAnimationStatus(M[e]);
            if (anim.is && !a.fastRoll) {
              return M[e].on($.jgo.prm.transitionEnd, function(t) {
                if (t.target === t.currentTarget) {
                  M[e].off($.jgo.prm.transitionEnd);
                  return i();
                }
              });
            } else {
              return i();
            }
          }
        };
        v = function(e) {
          var t;
          M[e].off($.jgo.prm.transitionEnd);
          M[e].removeClass(a.classeApres + ' ' + a.classeNonActive + ' ' + a.classeActive + ' ' + a.classeInit).addClass(a.classeRolling);
          t = $.jgo.getCssAnimationStatus(M[e]);
          if (t.is) {
            return M[e].on($.jgo.prm.transitionEnd, function(t) {
              if (t.target === t.currentTarget) {
                M[e].off($.jgo.prm.transitionEnd);
                return A(e);
              }
            });
          } else {
            return A(e);
          }
        };
        p = function() {
          T = !0;
          M[R].off($.jgo.prm.transitionEnd);
          m(R, !0);
          Jalis.checkAddedCode(c, 'startRoll', j());
          a.rightArrow !== null && (z === M.length - 1 ? N.right.addClass('disabled') : M.length > 1 && N.right.removeClass('disabled'));
          a.leftArrow !== null && (0 === z ? N.left.addClass('disabled') : M.length > 1 && N.left.removeClass('disabled'));
          if (a.genereBoutons) {
            return $('#' + i(s, a.repereBoutons)).find(a.classeBt).each(function(t) {
              if (t !== z) {
                return $(this).removeClass(a.classeBtActif);
              } else {
                return $(this).addClass(a.classeBtActif);
              }
            });
          }
        };
        b = function(e) {
          return M[e].removeClass(a.classeActive + ' ' + a.classeRolling + ' ' + a.classeInit).addClass(a.classeApres);
        };
        A = function(e) {
          var t;
          M[e].addClass(a.classeActive);
          t = $.jgo.getCssAnimationStatus(M[e]);
          if (t.is && !a.fastRoll) {
            return M[e].on($.jgo.prm.transitionEnd, function(t) {
              if (t.target === t.currentTarget) {
                M[e].off($.jgo.prm.transitionEnd);
                return x();
              }
            });
          } else {
            return x();
          }
        };
        x = function() {
          var e;
          y.removeClass('previous');
          for (e in M) {
            e === z || M[e].hasClass(a.classeRolling) || M[e].off($.jgo.prm.transitionEnd);
            M[e].removeClass(a.classeActive + ' ' + a.classeApres + ' ' + a.classeInit + ' ' + a.classeNonActive + ' ' + a.classeRolling);
          }
          Jalis.checkAddedCode(c, 'endRoll', j());
          T = !1;
          if (G.defilementAuto && S > 1) {
            return w();
          }
        };
        w = function() {
          return B = setTimeout((function() {
            if (!I && S > 1) {
              return K();
            }
          }), G.tempsAffichage);
        };
        C = function() {
          return window.clearTimeout(B);
        };
        k = function() {
          if ($(a.conteneur).hasClass($.jgo.clean(a.conteneur))) {
            return Jalis.identifier.slideshow + '_' + $.jgo.clean(a.conteneur) + '_' + s;
          } else {
            return $.jgo.clean(a.conteneur);
          }
        };
        E = function(str) {
          return $.jgo.clean(a.element) + '_' + str;
        };
        j = function() {
          return {
            indexSlider: s,
            current: z,
            oldcurrent: R,
            currentSlide: M[z],
            oldSlide: M[R],
            slideshow: y
          };
        };
        G.tempsAffichage = a.tempsAffichage;
        G.marcheAvant = a.marcheAvant;
        G.defilementAuto = a.defilementAuto;
        G.afficheAleatoire = a.afficheAleatoire;
        G.pauseOnHover = a.pauseOnHover;
        this.items = M;
        this.history = O;
        this.uprm = G;
        this.isPlaying = function() {
          return T;
        };
        this.init = function() {
          y = $(a.conteneur).eq(s);
          d();
          S = y.children(a.element).length;
          z = 0;
          R = 0;
          y.attr('id', k());
          y.children(a.element).each(function(t) {
            var n;
            n = $(this);
            n.attr('id', k() + '_' + E(t));
            M[t] = n;
            t !== z && M[t];
          });
          if (a.leftArrow !== null) {
            N.left = $('#' + i(s, a.leftArrow));
          }
          if (a.rightArrow !== null) {
            N.right = $('#' + i(s, a.rightArrow));
          }
          if (M.length > 0) {
            M[z].addClass(a.classeInit).addClass(a.classeActive);
            if (G.pauseOnHover) {
              if (window.navigator.msPointerEnabled) {
                y.on('MSPointerDown MSPointerOver', h);
                y.on('MSPointerUp MSPointerOut', f);
              } else if (window.navigator.PointerEnabled) {
                y.on('PointerDown PointerOver', h);
                y.on('PointerUp PointerOut', f);
              } else {
                y.on('mouseover', h);
                y.on('mouseleave', f);
              }
            }
            if (a.genereBoutons) {
              u();
              $('#' + i(s, a.repereBoutons)).find(a.classeBt).eq(z).addClass(a.classeBtActif);
            }
            if (a.leftArrow !== null && a.rightArrow !== null) {
              if (M.length > 1) {
                g();
                N.left.addClass('disabled');
              } else {
                N.left.addClass('disabled');
                N.right.addClass('disabled');
              }
            }
            if (a.handleKeyboard) {
              $(window).keydown(function(e) {
                e.keyCode === a.nextKey && K();
                e.keyCode === a.previousKey && H();
              });
            }
            if (G.defilementAuto && S > 1) {
              w();
            }
          } else {
            a.leftArrow !== null && N.left.addClass('disabled');
            a.rightArrow !== null && N.right.addClass('disabled');
          }
        };
        this.next = function() {
          var e, t;
          e = function() {
            var it, last, n, stack, t;
            if (C()) {
              O.push({
                current: z,
                oldcurrent: R
              });
              T || (R = z);
              G.afficheAleatoire;
              stack = [];
              if (P.length === M.length) {
                last = P[P.length - 1];
                P = [last];
              }
              for (t in M) {
                n = !0;
                for (it in P) {
                  if (P[it] === t) {
                    n = !1;
                  }
                }
                if (n) {
                  stack.push(t);
                }
              }
              z = stack[Math.floor(Math.random() * stack.length)];
              P.push(z);
            } else {
              if (G.marcheAvant) {
                (z === S - 1 ? (z = 0) : z++);
              } else if (0 === z) {
                (z = S - 1);
              } else {
                z--;
              }
            }
            p();
          };
          t = function() {
            T && parseInt(R) !== parseInt(z) && M[R].off($.jgo.prm.transitionEnd);
            M[z].off($.jgo.prm.transitionEnd);
            m(R);
            m(z);
            e();
          };
          t();
          return j();
        };
        this.previous = function() {
          var e, t;
          e = function() {
            var stack, t;
            if (C()) {
              y.addClass('previous');
              O.length >= 1;
              stack = [];
              for (t in O) {
                if (t < O.length - 1) {
                  stack.push(O[t]);
                } else {
                  R = z;
                }
                z = O[t].current;
              }
              O = stack;
              p();
            }
          };
          t = function() {
            T && parseInt(R) !== parseInt(z) && M[R].off($.jgo.prm.transitionEnd);
            M[z].off($.jgo.prm.transitionEnd);
            m(R);
            m(z);
            e();
          };
          t();
          return j();
        };
        this.goSlide = function(e) {
          var t;
          t = function() {
            e < M.length && e >= 0 && parseInt(e) !== parseInt(z) && C();
            O.push({
              current: z,
              oldcurrent: R
            });
            T || (R = z);
            z = e;
            p();
          };
          i = function() {
            T && parseInt(e) !== parseInt(z) && M[R].off($.jgo.prm.transitionEnd);
            M[z].off($.jgo.prm.transitionEnd);
            m(R);
            m(z);
            t();
          };
          i();
          return j();
        };
        this.kill = function() {
          M[R].off($.jgo.prm.transitionEnd);
          M[z].off($.jgo.prm.transitionEnd);
          C();
        };
        this.reset = function() {
          o();
          y.children(a.element).each(function() {
            $(this).attr('class', $.jgo.clean(a.element));
          });
          M = [];
          O = [];
          P = [0];
          T = !1;
          I = !1;
        };
        this.restart = function() {
          l();
          r();
        };
        K = this.next;
        H = this.previous;
        _ = this.goSlide;
        this.init();
      };
      arg = arguments[0] ? arguments[0] : !1;
      a.conteneur = 0 !== arg && Jalis.exists(arg.conteneur) ? arg.conteneur : '.slideshow';
      a.element = 0 !== arg && Jalis.exists(arg.element) ? arg.element : '.slide';
      a.marcheAvant = 0 !== arg && Jalis.exists(arg.marcheAvant) ? arg.marcheAvant : !0;
      a.tempsAffichage = 0 !== arg && Jalis.exists(arg.tempsAffichage) ? arg.tempsAffichage : 3e3;
      a.tempsAffichageIE = 0 !== arg && Jalis.exists(arg.tempsAffichageIE) ? arg.tempsAffichageIE : 3e3;
      a.defilementAuto = 0 !== arg && Jalis.exists(arg.defilementAuto) ? arg.defilementAuto : !0;
      a.afficheAleatoire = 0 !== arg && Jalis.exists(arg.afficheAleatoire) ? arg.afficheAleatoire : !1;
      a.pauseOnHover = 0 !== arg && Jalis.exists(arg.pauseOnHover) ? arg.pauseOnHover : !1;
      a.handleKeyboard = 0 !== arg && Jalis.exists(arg.handleKeyboard) ? arg.handleKeyboard : !1;
      a.previousKey = 0 !== arg && Jalis.exists(arg.previousKey) ? arg.previousKey : 37;
      a.nextKey = 0 !== arg && Jalis.exists(arg.nextKey) ? arg.nextKey : 39;
      a.genereBoutons = 0 !== arg && Jalis.exists(arg.genereBoutons) ? arg.genereBoutons : !1;
      a.exempleBouton = 0 !== arg && Jalis.exists(arg.exempleBouton) ? arg.exempleBouton : '.slideshow-bt-exemple';
      a.repereBoutons = 0 !== arg && Jalis.exists(arg.repereBoutons) ? arg.repereBoutons : '.slideshow-bts';
      a.placeNum = 0 !== arg && Jalis.exists(arg.placeNum) ? arg.placeNum : !0;
      a.repereNum = 0 !== arg && Jalis.exists(arg.repereNum) ? arg.repereNum : '.slideshow-bt';
      a.classeBt = 0 !== arg && Jalis.exists(arg.classeBt) ? arg.classeBt : '.slideshow-bt';
      a.classeBtActif = 0 !== arg && Jalis.exists(arg.classeBtActif) ? arg.classeBtActif : $.jgo.prm.defaultActiveClass;
      a.fastRoll = 0 !== arg && Jalis.exists(arg.fastRoll) ? arg.fastRoll : !0;
      a.leftArrow = 0 !== arg && Jalis.exists(arg.leftArrow) ? arg.leftArrow : null;
      a.rightArrow = 0 !== arg && Jalis.exists(arg.rightArrow) ? arg.rightArrow : null;
      a.classeApres = 0 !== arg && Jalis.exists(arg.classeApres) ? arg.classeApres : 'apres';
      a.classeRolling = 0 !== arg && Jalis.exists(arg.classeRolling) ? arg.classeRolling : 'rolling';
      a.classeNonActive = 0 !== arg && Jalis.exists(arg.classeNonActive) ? arg.classeNonActive : 'unactive';
      a.classeActive = 0 !== arg && Jalis.exists(arg.classeActive) ? arg.classeActive : $.jgo.prm.defaultActiveClass;
      a.classeInit = 0 !== arg && Jalis.exists(arg.classeInit) ? arg.classeInit : 'init';
      a.autoinit = 0 !== arg && Jalis.exists(arg.autoinit) ? arg.autoinit : !0;
      a.elems = [];
      this.prm = a;
      this.init = function() {
        $.jgo.nav.bon || (a.tempsAffichage = a.tempsAffichageIE);
        if (a.genereBoutons) {
          $(a.exempleBouton).each(function(t) {
            $(this).attr('id', i(t, a.exempleBouton));
          });
          $(a.repereBoutons).each(function(t) {
            $(this).attr('id', i(t, a.repereBoutons));
          });
        }
        if (a.leftArrow !== null && $.jgo.elementExiste(a.leftArrow)) {
          $(a.leftArrow).each(function(t) {
            $(this).attr('id', i(t, a.leftArrow));
          });
        }
        if (a.rightArrow !== null && $.jgo.elementExiste(a.rightArrow)) {
          $(a.rightArrow).each(function(t) {
            $(this).attr('id', i(t, a.rightArrow));
          });
        }
        $(a.conteneur).each(function(e) {
          a.elems.push(new s(e));
        });
      };
      this.next = function() {
        var e, t;
        e = [];
        for (t in a.elems) {
          e.push(a.elems[t].next());
        }
        return e;
      };
      this.previous = function() {
        var e, t;
        e = [];
        for (t in a.elems) {
          e.push(a.elems[t].previous());
        }
        return e;
      };
      this.goSlide = function(e) {
        var n, t;
        t = [];
        for (n in a.elems) {
          t.push(a.elems[n].goSlide(e));
        }
        return t;
      };
      this.isPlaying = function() {
        var e, t;
        e = !1;
        for (t in a.elems) {
          a.elems[t].isPlaying() && (e = !0);
        }
        return e;
      };
      this.kill = function() {
        var e;
        for (e in a.elems) {
          a.elems[e].kill();
        }
      };
      this.reset = function() {
        var e;
        for (e in a.elems) {
          a.elems[e].reset();
        }
      };
      this.restart = function() {
        this.reset();
        a.elems = [];
        r();
      };
      this.ajoutFonction = function(e, t) {
        var n;
        if (t === 'startRoll' || t === 'endRoll') {
          n = new Array(e, t);
          c.push(n);
        }
      };
      r = this.init;
      o = this.kill;
      l = this.reset;
      c = new Array();
      this.ajoutFonction;
      this.restart;
      a.autoinit && $.jgo.elementExiste(a.conteneur) && this.init();
    },
    imgback: function() {
      var a, arg, c, i, l, o, r, s;
      a = new Object();
      arg = void 0;
      i = function() {
        var el;
        for (el in a.elems) {
          $(a.elems[el]).each(function() {
            var container, img, src, ws;
            img = $(this).children('img');
            src = img.attr('src');
            if (src !== null) {
              ws = new RegExp(' ', 'gi');
              container = $(this);
              src = src.replace(ws, '%20');
              img.remove();
              container.css({
                'background-image': 'url("' + src + '")',
                'background-size': a.bgSize,
                'background-position': a.bgPos,
                'background-repeat': a.bgRpt
              });
            }
          });
        }
        Jalis.checkAddedCode(c, 'resized', a);
      };
      s = function() {
        var el;
        for (el in a.elems) {
          $(a.elems[el]).each(function() {
            var img, n, t;
            t = $(this);
            n = t.css('background-image').slice(4, -1);
            if (('"' === n.substring(0, 1) || '\'' === n.substring(0, 1)) && (n = n.substring(1))) {
              ('"' === n.slice(-1) || '\'' === n.slice(-1)) && (n = n.slice(0, -1));
              void 0 !== n;
              img = '<img src=\'' + n + '\' />';
              t.append(img);
              return t.css({
                'background-image': 'none',
                'background-size': 'inherit',
                'background-position': 'inherit',
                'background-repeat': 'inherit'
              });
            }
          });
        }
        return Jalis.checkAddedCode(c, 'unresized', a);
      };
      arg = arguments[0] ? arguments[0] : !1;
      a.elems = 0 !== arg && Jalis.exists(arg.elements) ? arg.elements : ['.img--back'];
      a.bgSize = 0 !== arg && Jalis.exists(arg.backgroundsize) ? arg.backgroundsize : 'cover';
      a.bgPos = 0 !== arg && Jalis.exists(arg.backgroundposition) ? arg.backgroundposition : 'center center';
      a.bgRpt = 0 !== arg && Jalis.exists(arg.backgroundrepeat) ? arg.backgroundrepeat : 'no-repeat';
      a.autoinit = 0 !== arg && Jalis.exists(arg.autoinit) ? arg.autoinit : !0;
      a.compat = [];
      this.prm = a;
      this.init = function() {
        return $.jgo.nav.bon && i();
      };
      this.kill = function() {};
      this.reset = function() {
        $.jgo.nav.bon && o();
        return s();
      };
      this.restart = function() {
        $.jgo.nav.bon && o();
        l();
        return r();
      };
      this.resize = function() {};
      this.ajoutFonction = function(e, t) {
        var n;
        if (t === 'resized' || t === 'unresized') {
          n = new Array(e, t);
          return c.push(n);
        }
      };
      r = this.init;
      o = this.kill;
      l = this.reset;
      c = new Array();
      this.ajoutFonction;
      this.restart;
      this.resize;
      a.autoinit && this.init();
    },
    touchNav: function() {
      var arg, i, s;
      s = new Object();
      arg = void 0;
      i = function() {
        var el, results;
        results = [];
        for (el in s.navPath) {
          results.push($(s.navPath[el]).each(function() {
            var results1, t, tgt;
            t = $(this);
            if ($.jgo.elementExiste(t.children(s.sousMenu))) {
              results1 = [];
              for (tgt in s.navTarget) {
                if (s.navTarget[tgt] === 'a') {
                  results1.push(t.children(s.navTarget[tgt]).attr('href', 'javascript:void(0);'));
                } else {
                  results1.push(t.children(s.navTarget[tgt]).attr('onclick', 'void(0);'));
                }
              }
              return results1;
            }
          }));
        }
        return results;
      };
      arg = arguments[0] ? arguments[0] : !1;
      s.navPath = 0 !== arg && Jalis.exists(arg.navPath) ? arg.navPath : ['nav > ul > li', 'nav > ul > li > span'];
      s.navTarget = 0 !== arg && Jalis.exists(arg.navTarget) ? arg.navTarget : ['a', 'span'];
      s.sousMenu = 0 !== arg && Jalis.exists(arg.sousMenu) ? arg.sousMenu : 'ul';
      s.autoinit = 0 !== arg && Jalis.exists(arg.autoinit) ? arg.autoinit : !0;
      this.init = function() {
        return i();
      };
      return s.autoinit && this.init();
    },
    mobileMenu: function() {
      var a, arg, i, r, s;
      r = new Object();
      arg = void 0;
      i = function() {
        var results, t;
        $(r.attachElem).addClass(r.classeActive);
        results = [];
        for (t in r.releaseElems) {
          results.push($(r.releaseElems[t]).on('click', function(e) {
            e.preventDefault();
            return s();
          }));
        }
        return results;
      };
      s = function() {
        var results, t;
        $(r.attachElem).removeClass(r.classeActive);
        results = [];
        for (t in r.releaseElems) {
          results.push($(r.releaseElems[t]).off('click'));
        }
        return results;
      };
      a = function() {
        return $(r.btMenu).on('click', function() {
          if ($(r.attachElem).hasClass(r.classeActive)) {
            return s();
          } else {
            return i();
          }
        });
      };
      arg = arguments[0] ? arguments[0] : !1;
      r.btMenu = 0 !== arg && Jalis.exists(arg.btMenu) ? arg.btMenu : '#btMenu';
      r.classeActive = 0 !== arg && Jalis.exists(arg.classeActive) ? arg.classeActive : 'menu-active';
      r.attachElem = 0 !== arg && Jalis.exists(arg.attachElem) ? arg.attachElem : 'body';
      r.releaseElems = 0 !== arg && Jalis.exists(arg.releaseElems) ? arg.releaseElems : $.jgo.prm.menuMobileReleaseElems;
      r.autoinit = 0 !== arg && Jalis.exists(arg.autoinit) ? arg.autoinit : !0;
      this.init = a;
      this.affiche = i;
      this.cache = s;
      return r.autoinit && this.init();
    },
    scrollWatcher: function() {
      var a, arg, i, r, s;
      a = new Object();
      arg = void 0;
      i = function() {
        s();
        return $(window).on('scroll', function() {
          return s();
        });
      };
      s = function() {
        var n;
        n = $(window).scrollTop();
        if (n > a.breakPoint && !$('body').hasClass(a.classe)) {
          $('body').addClass(a.classe);
          return Jalis.checkAddedCode(r, 'scroll');
        } else {
          if (n <= a.breakPoint && $('body').hasClass(a.classe)) {
            $('body').removeClass(a.classe);
            return Jalis.checkAddedCode(r, 'noscroll');
          }
        }
      };
      arg = arguments[0] ? arguments[0] : !1;
      a.classe = 0 !== arg && Jalis.exists(arg.classe) ? arg.classe : 'scrolled';
      a.breakPoint = 0 !== arg && Jalis.exists(arg.breakPoint) ? arg.breakPoint : $.jgo.prm.scrollPoint();
      a.autoinit = 0 !== arg && Jalis.exists(arg.autoinit) ? arg.autoinit : !0;
      this.ajoutFonction = function(e, t) {
        var n;
        if (t === 'scroll' || t === 'noscroll') {
          n = new Array(e, t);
          return r.push(n);
        }
      };
      r = new Array();
      this.init = i;
      this.prm = a;
      return a.autoinit && this.init();
    },
    paddingMenu: function() {
      var arg, i, s;
      s = new Object();
      arg = void 0;
      i = function() {
        var n, padding, t;
        t = $(s.conteneur).width();
        n = $(s.isTouch ? s.touchElement : s.element);
        n.css({
          'padding-left': 0,
          'padding-right': 0
        });
        n.each(function() {
          return t -= $(this).outerWidth(!0);
        });
        padding = Math.floor(t / n.length / 2) - 1;
        if (padding > 0) {
          return n.css({
            'padding-left': padding + 'px',
            'padding-right': padding + 'px'
          });
        }
      };
      arg = arguments[0] ? arguments[0] : !1;
      s.conteneur = 0 !== arg && Jalis.exists(arg.conteneur) ? arg.conteneur : 'nav > ul';
      s.element = 0 !== arg && Jalis.exists(arg.element) ? arg.element : 'nav > ul > li > a';
      s.isTouch = 0 !== arg && Jalis.exists(arg.isTouch) ? arg.isTouch : !1;
      s.touchElement = 0 !== arg && Jalis.exists(arg.touchElement) ? arg.touchElement : 'nav > ul > li > span > a, nav > ul > li > span > span';
      this.init = function() {
        i();
        if ($.jgo.prm.handleResize) {
          return $.jgo.handledResize.push(i);
        }
      };
      this.kill = function() {};
      this.resize = function() {
        return i();
      };
      this.restart = function() {};
      return this.init();
    },
    launch: function() {
      $(window).on('load', function() {
        return $.jgo.globalResize();
      });
      if ($.jgo.nav.mobile) {
        $(window).on('orientationchange', function() {
          return $.jgo.globalResize();
        });
      } else {
        $(window).on('resize', function() {
          return $.jgo.globalResize();
        });
      }
      $.jgo.nav.mobile && $('html').addClass('jgo-mobile');
      $.jgo.prm.handleTouchNav && ($.site.touchNav = $.jgo.touchNav());
      $.jgo.prm.watchScroll && ($.site.scrollWatch = new ($.jgo.scrollWatcher)());
      $.jgo.prm.menuMobile && ($.site.menu = new ($.jgo.mobileMenu)());
      if ($.jgo.prm.handleTextInput) {
        $('form').each(function() {
          return $(this).find($.jgo.prm.textInputException).each(function() {
            var t;
            t = $(this).val();
            $(this).focusin(function() {
              return $(this).val() === t && $(this).val('');
            });
            return $(this).focusout(function() {
              return ($(this).val() === t || '' === $(this).val()) && $(this).val(t);
            });
          });
        });
      }
      if ($.jgo.prm.handleIframeWmode) {
        $('iframe').each(function() {
          var a, i, n, s, t;
          t = $(this).attr('src');
          n = 'wmode=transparent';
          if (t !== null) {
            if (t.indexOf('?') !== -1) {
              i = t.split('?');
              s = i[1];
              a = i[0];
              return $(this).attr('src', a + '?' + n + '&' + s);
            } else {
              return $(this).attr('src', t + '?' + n);
            }
          }
        });
      }
      if ($.jgo.prm.debug) {
        $.jgo.jgoLog('BROWSER | good nav : ' + $.jgo.nav.bon + ' | touch : ' + $.jgo.nav.tactile + ' | mobile : ' + $.jgo.nav.mobile);
        $.jgo.jgoLog('CONFIG | resize handled : ' + $.jgo.prm.handleResize + ' | touch handled : ' + $.jgo.prm.handleTouchNav + ' | responsive handled : ' + $.jgo.prm.handleRespResize + ' | defaultActiveClass : ' + $.jgo.prm.defaultActiveClass);
      }
      $.jgo.prm.ready();
      if ($('body').hasClass('loaded')) {
        return $.jgo.prm.load();
      } else {
        return $(window).on('load', function() {
          return $.jgo.prm.load();
        });
      }
    },
    init: function() {
      var i, s, t;
      if ($.jgo.nav.bon || $('body').addClass('ie')) {
        $('body').addClass((Modernizr.backgroundsize ? 'ie9' : 'ie8'));
        !Modernizr.svg && $.jgo.prm.replaceSVGtoPNG;
        t = /svg/gi;
        $('img').each(function() {
          var n;
          n = $(this).attr('src');
          if (n.match(t)) {
            n = n.replace(t, 'png');
            return $(this).attr('src', n);
          }
        });
        for (i in $.jgo.prm.cssSvgBackground) {
          s = $($.jgo.prm.cssSvgBackground[i]).css('background-image');
          if (s !== null && s.match(t)) {
            s = s.replace(t, 'png');
            $($.jgo.prm.cssSvgBackground[i]).css('background-image', s);
          }
        }
      }
      return $.jgo.launch();
    }
  };
  return $.jgo = jgo;
});

// coffeelint: enable=max_line_length
