/*
JALIS INNER RESPONSIVE 0.1
by Pierre Bonnin - @PierreBonninPRO - 2015
*/
(function(factory) {
  'use strict';
  if (typeof define === 'function' && define.amd) {
    // AMD. Register as an anonymous module.
    return define(['jquery'], factory);
  } else if (typeof module === 'object' && module.exports) {
    // Node/CommonJS
    return module.exports = function(root, jQuery) {
      if (jQuery === void 0) {
        // require('jQuery') returns a factory that requires window to
        // build a jQuery instance, we normalize how we use modules
        // that require this pattern but the window provided is a noop
        // if it's defined (how jquery works)
        if (typeof window !== 'undefined') {
          jQuery = require('jquery');
        } else {
          jQuery = require('jquery')(root);
        }
      }
      return factory(jQuery);
    };
  } else {
    // Browser globals
    return factory(jQuery);
  }
})(function($) {
  'use strict';
  var jalisInnerResponsive;
  jalisInnerResponsive = function(options) {
    var defaults, elems, prm, selector, watch;
    defaults = {
      step: '640',
      cssClass: 'small',
      outerWidth: false
    };
    prm = $.extend(defaults, options);
    elems = this;
    selector = this.selector;
    watch = function() {
      return elems.each(function(_e) {
        var elem, width;
        elem = $(this);
        width = prm.outerWidth ? elem.outerWidth(true) : elem.width();
        if (width <= prm.step) {
          if (!elem.hasClass(prm.cssClass)) {
            return elem.addClass(prm.cssClass);
          }
        } else {
          if (elem.hasClass(prm.cssClass)) {
            return elem.removeClass(prm.cssClass);
          }
        }
      });
    };
    $(window).on('resize', function() {
      return watch();
    });
    watch();
    this.update = function() {
      elems = $(selector);
      return watch();
    };
    return this;
  };
  return $.fn.jalisInnerResponsive = jalisInnerResponsive;
});
