/*
JALISWALL 0.3
by Pierre Bonnin - @PierreBonninPRO - 2015
*/
(function(factory) {
  'use strict';
  if (typeof define === 'function' && define.amd) {
    // AMD. Register as an anonymous module.
    return define(['jquery'], factory);
  } else if (typeof module === 'object' && module.exports) {
    // Node/CommonJS
    return module.exports = function(root, jQuery) {
      if (jQuery === void 0) {
        // require('jQuery') returns a factory that requires window to
        // build a jQuery instance, we normalize how we use modules
        // that require this pattern but the window provided is a noop
        // if it's defined (how jquery works)
        if (typeof window !== 'undefined') {
          jQuery = require('jquery');
        } else {
          jQuery = require('jquery')(root);
        }
      }
      return factory(jQuery);
    };
  } else {
    // Browser globals
    return factory(jQuery);
  }
})(function($) {
  'use strict';
  var jaliswall;
  jaliswall = function(options) {
    this.each(function() {
      var container, defaults, elemsDatas, getNbCols, init, items, nbCols, parseSelector, print, prm, recordAndRemove, setColPos;
      init = function() {
        var nbCols;
        nbCols = getNbCols();
        recordAndRemove();
        print();
        if (prm.resize) {
          return $(window).resize(function() {
            if (nbCols !== getNbCols()) {
              nbCols = getNbCols();
              setColPos();
              return print();
            }
          });
        }
      };
      getNbCols = function() {
        var colWidth, instanceForCompute, wallWidth;
        instanceForCompute = false;
        if (container.find(prm.columnClass).length === 0) {
          instanceForCompute = true;
          container.append('<div class=\'' + parseSelector(prm.columnClass) + '\'></div>');
        }
        colWidth = container.find(prm.columnClass).outerWidth(true);
        wallWidth = container.innerWidth();
        if (instanceForCompute) {
          container.find(prm.columnClass).remove();
        }
        return Math.round(wallWidth / colWidth);
      };
      // save items content and params and removes originals items
      recordAndRemove = function() {
        return items.each(function(index) {
          var item;
          item = $(this);
          elemsDatas.push({
            content: item[0].outerHTML,
            colid: index % nbCols
          });
          return item.remove();
        });
      };
      // determines in which column an item should be
      setColPos = function() {
        var i, results;
        results = [];
        for (i in elemsDatas) {
          results.push(elemsDatas[i].colid = i % nbCols);
        }
        return results;
      };
      // to get a class name without the selector
      parseSelector = function(selector) {
        return selector.slice(1, selector.length);
      };
      // write and append html
      print = function() {
        var content, html, i, results, tree;
        tree = '';
        i = 0;
        while (i < nbCols) {
          tree += '<div class=\'' + parseSelector(prm.columnClass) + '\'></div>';
          i++;
        }
        container.html(tree);
// creates items
        results = [];
        for (i in elemsDatas) {
          html = '';
          content = elemsDatas[i].content !== void 0 ? elemsDatas[i].content : '';
          html += content;
          results.push(container.children(prm.columnClass).eq(i % nbCols).append(html));
        }
        return results;
      };
      // creates a well-formed attribute
      // getAttr = (attr, type) ->
      //   if attr != undefined then type + '=\'' + attr + '\'' else ''
      defaults = {
        item: '.wall-item',
        columnClass: '.wall-column',
        resize: true
      };
      prm = $.extend(defaults, options);
      container = $(this);
      items = container.find(prm.item);
      elemsDatas = [];
      nbCols = getNbCols();
      return init();
    });
    return this;
  };
  return $.fn.jaliswall = jaliswall;
});
